import { useState } from "react";
import { isStaging } from "./utilities/index";
import "./App.module.css";
import StagingPage from "./containers/staging";
import HomePage from "./containers/HomePage";

function App() {
    const [isAuthorizedVisit, setIsAuthorizedVisit] = useState(localStorage.getItem("rememberMe") ?? false);

    const handleAllowed = () => {
        setIsAuthorizedVisit(true);
    };

    return <div className="App">{isAuthorizedVisit === false && isStaging ? <StagingPage handleAllowed={() => handleAllowed()} /> : <HomePage />}</div>;
}

export default App;
