import classes from "../App.module.css";
import Header from "../components/common/header/Header";
import FavCategories from "../components/home/favCategories";

const HomePage = () => {
    return (
        <div className="wrapper">
            <Header />
            <div className={classes.favWrapper}>
                <div className={classes.container}>
                    <FavCategories />
                </div>
            </div>
        </div>
    );
};

export default HomePage;
