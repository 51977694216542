import classes from "../../../App.module.css";

const FavCategories = () => {
    return (
        <div className={classes.favContainer}>
            <div className={classes.favItem}>
                <img src="./images/svg/house.svg" alt="Property" />
                <h2>Properties</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/car.svg" alt="Car" />
                <h2>Car & Vehicles</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/motorcycle.svg" alt="motorcycle" />
                <h2>Motorcycles</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/smartphone.svg" alt="Mobile" />
                <h2>Mobile & Tablets</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/laptop.svg" alt="laptop" />
                <h2>Laptop & Desktops</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/television.svg" alt="television" />
                <h2>Televisions</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/loudspeaker.svg" alt="loudspeaker" />
                <h2>Audio Systems</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/camera.svg" alt="camera" />
                <h2>Camera</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/couch.svg" alt="couch" />
                <h2>Furniture</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/kitchen.svg" alt="kitchen" />
                <h2>Kitchen & Appliances</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/dress.svg" alt="dress" />
                <h2>Fashions</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/beauty.svg" alt="beauty" />
                <h2>Health & Beauty</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/book.svg" alt="book" />
                <h2>Books</h2>
            </div>
            <div className={classes.favItem}>
                <img src="./images/svg/24-hours.svg" alt="24-hours" />
                <h2>Services</h2>
            </div>
        </div>
    );
};

export default FavCategories;
