import classes from "../../../App.module.css";
import Logo from "../../../assets/images/logo-web.png";
const Header = () => {
    return (
        <div className={classes.headerContainer}>
            <div className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.header_left}>
                        <div className={classes.header_logo}>
                            <img className={classes.header_logo__img} src={Logo} alt="Clikpa" />
                            <div className={classes.header_logo_mobile_header}>
                                <button className={classes.header_menu__authButton}>
                                    <i className="fa fa-list-ul"></i>
                                    <strong>Categories</strong>
                                </button>
                                <i className={`fa fa-user-circle ${classes.userIcon}`}></i>
                            </div>
                        </div>
                        <div className={classes.header_search}>
                            <input className={classes.header_search__input} type="text" placeholder="search items" />
                            <div className={classes.header_search__location}>
                                <i className={`fa fa-map-marker ${classes.header_search__location__mapIcon}`}></i>
                                <span>Any Location</span>
                                <i className={`fa fa-caret-down ${classes.header_search__location__caretIcon}`}></i>
                            </div>
                            <button className={classes.header_search__button}>
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className={classes.header_right}>
                        <div className={classes.header_menu}>
                            <button className={classes.header_menu__authButton}>
                                <i className="fa fa-list-ul"></i>
                                <strong>Categories</strong>
                            </button>
                            <button className={classes.header_menu__authButton}>
                                <i className="fa fa-user-circle"></i>
                                <strong>Login / Register</strong>
                            </button>
                            <button className={classes.header_menu__sellButton}>
                                <i className="fa fa-money"></i> <span>Sell</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
