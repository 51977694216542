import { useState } from "react";
import classes from "../../App.module.css";

const StagingPage = (props) => {
    const [value, setValue] = useState("");
    const handleSubmit = () => {
        if (value === "w@tchme") {
            localStorage.setItem("rememberMe", true);
            props.handleAllowed();
        }
    };
    return (
        <div className={classes.staging_box}>
            <input onChange={(e) => setValue(e.target.value)} className={classes.staging_box__input} type="password" placeholder="Enter the password" />
            <button onClick={() => handleSubmit()} type="button" className={classes.staging_box__btn}>
                Submit
            </button>
        </div>
    );
};

export default StagingPage;
